.ant-notification {
  z-index: 999999 !important;
}
.MuiModal-root {
  z-index: 999 !important;
}
// .MuiModal-root {
//   z-index: 9999999 !important;
// }

/* LOGIN */
.login {
  background-image: url("../images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.inputLogin {
  border-bottom: 1px solid #bbb !important;
}

.inputLogin input {
  color: #fff !important;
}

.inputLogin label {
  color: #bbb !important;
}

.formContent {
  width: 75%;
  max-width: 400px;
  color: #fff;
  z-index: 9999;
}

table tr th {
  background: #1e88e5 !important;
  color: white !important;
}

table tr th,
table tr td {
  border: 1px solid rgba(200, 200, 200, 0.5);
}

.print-source {
  display: none;
}

body > .print-source {
  display: block;
}

@media print {
  .print-source {
    display: block;
  }
}
.input-dish-detail .input-dish-detail::-webkit-outer-spin-button,
.input-dish-detail::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-dish-detail[type="number"] {
  -moz-appearance: textfield;
}

// NAV MENU PUBLICO
.nav-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  visibility: hidden;
  opacity: 0;
}
.active {
  transform: translateX(0%) !important;
}
.list-container {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  transition: all 300ms ease-in-out;
  list-style: none;
}
.navbar-filter {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}

@media (max-width: 991px) {
  .list-container {
    position: absolute;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    transform: translateX(-200%);
  }
  .nav-icon {
    visibility: visible;
    opacity: 1;
    z-index: 101;
  }
  // .navbar-filter-active {
  //   visibility: visible;
  // }
  .boxNav {
    margin-left: 60px;
  }
  .navbar-filter-active {
    visibility: visible;
  }
}

.slick-slider {
  margin-top: 100px;
}
.slides {
  position: relative;
  .slick-prev,
  .slick-next {
    position: absolute;
    top: 50%;
  }
  .slick-prev {
    z-index: 100;
    left: 5%;
  }
  .slick-next {
    right: 5%;
  }
}
@keyframes moveLateral {
  0% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  99% {
    transform: translateX(10px);
  }
}
.vueltoBeat {
  animation: moveLateral 1s infinite;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
.bell-shake {
  animation: bellshake 1s infinite;
}

.cartShake {
  animation: bellshake 1s;
}
