$primary: #003399;
$wpp: #25d366;
$white: #fff;
html {
  scroll-behavior: smooth !important;
}
body {
  scroll-behavior: smooth !important;
  overflow-y: scroll;
  width: 100%;
}
// asdasd
// ¿UTILS?
.important {
  font-weight: 700;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.publicContainer {
  max-width: 1280px;
  margin: auto;
  padding: 0 20px;
}

// HERO
.hero {
  width: 100%;
  height: 769.125px;
  background-image: url("../images/HomeHero.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  &__text {
    font-size: 3.5rem;
    color: $white;
    padding: 0 100px;
  }
  &__button {
    color: $primary;
    background-color: $white;
    padding: 10px;
    border: 1px solid $white;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 300ms ease;
    width: 50%;
    font-weight: 700;
    &__container {
      display: flex;
      justify-content: space-between;
      padding: 0 100px;
    }

    &:hover {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;
    }
  }
}

// CELULAR
.celular {
  padding: 150px 0;
  position: relative;
  &__image {
    position: absolute;
    bottom: 0;
    height: 100%;
    z-index: -1;
  }
  &__title {
    font-size: 4rem;
    color: $primary;
    padding: 0 20px;
    line-height: 1.3;
    &__important {
      font-weight: 700;
    }
    background-color: rgba($color: #fff, $alpha: 0.8);
  }
  &__text {
    font-size: 2rem;
    padding: 0 80px;
    line-height: 1.3;
    color: $primary;
    background-color: rgba($color: #fff, $alpha: 0.8);
  }
}

// BENEFICIOS
.beneficios {
  &__button {
    color: $primary;
    background-color: $white;
    padding: 10px 40px;
    border: 1px solid $primary;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 300ms ease;
    font-weight: 700;
    &:hover {
      background-color: $primary;
      color: $white;
      border: 1px solid $white;
    }
  }
  &__grid {
    padding-top: 50px;
    padding-right: 30px;
    &__title {
      text-align: center;
      color: $primary;
      font-size: 2rem;
    }
  }
  &__title {
    color: $primary;
    font-size: 1.5rem;
  }
  &__text {
    color: $primary;
    font-size: 1rem;
  }
  &__image {
    height: 450px;
    margin-left: -60px;
    // width: 100%;
    // transform: scale(0.5);
    // &__container {
    //   width: 100%;
    // }
    &__container {
      overflow: hidden;
    }
  }
}
// ICONS SECTION
.iconsSection {
  background-color: #e5e5e5;
  margin-top: 70px;
  padding: 60px 0;
  color: $primary;

  &__title {
    text-align: center;
    color: $primary;
    font-size: 2rem;
  }
  &__subtitle {
    text-align: center;
    color: $primary;
    font-size: 1.2rem;
  }
  &__description {
    text-align: justify;
    font-size: 1rem;
  }
  &__image {
    height: 100px;
    margin-bottom: 20px;
  }
}
// PRICE CARD
.price {
  &__container {
    margin: 0;
    overflow: hidden;
    padding: 175px 0px 50px;
  }
  &__mainTitle {
    color: $primary;
    font-size: 2rem;
    text-align: center;
    // margin-bottom: 175px;
    margin-top: 40px;
  }
  &__card {
    background-color: $primary;
    width: 350px;
    // height: 100vh;
    background: $primary;
    transform: skew(-15deg) rotatez(-10deg);
    border-radius: 40px;
    // margin: auto;
    position: relative;
    padding-bottom: 20px;
  }
  &__title {
    color: $white;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 130px;
    padding-left: 30px;
    font-weight: 700;
  }
  &__text {
    color: $white;
    text-align: justify;
    font-size: 1.3rem;
    padding: 0 40px;
    &__check {
      margin-right: 10px;
      width: 20px;
    }
    &__container {
      transform: skew(15deg) rotatez(10deg);
    }
  }
  &__circle {
    width: 250px;
    height: 250px;
    background: linear-gradient(90deg, #1488cc 0%, #2b32b2 100%);
    position: absolute;
    border-radius: 50%;
    transform: skew(15deg) rotatez(10deg);
    top: -125px;
    left: 50px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    &__text {
      color: $white;
      text-align: center;
      position: relative;
      font-weight: 700;
      &__sol {
        position: absolute;
        font-size: 1.8rem;
        top: 20px;
        left: -10px;
      }
      &__precio {
        font-size: 8rem;
      }
      &__mes {
        position: absolute;
        font-size: 1.8rem;
        bottom: 0px;
        right: -10px;
      }
    }
  }
  &__bigCircle {
    width: 300px;
    height: 300px;
    background: $primary;
    position: absolute;
    border-radius: 50%;
    transform: skew(15deg) rotatez(10deg);
    top: -150px;
    left: 25px;
    z-index: 1;
  }
  &__button {
    position: absolute;
    padding: 5px 40px;
    bottom: -15px;
    left: 100px;
    font-size: 1.2rem;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    transform: skew(15deg) rotatez(10deg);
    box-shadow: 32.1291px 23.8269px 18px rgba(21, 41, 142, 0.12),
      18.9176px 14.0293px 9.3888px rgba(21, 41, 142, 0.086784),
      9.71584px 7.20526px 4.4064px rgba(21, 41, 142, 0.067392),
      3.9069px 2.89735px 1.9296px rgba(21, 41, 142, 0.052608),
      0.873911px 0.648092px 0.8352px rgba(21, 41, 142, 0.033216);
    border-radius: 20px;
    cursor: pointer;
    font-weight: 700;
  }
}
// CONTACT HOME
.contactHome {
  &__title {
    color: $primary;
    font-size: 2.5rem;
    text-align: center;
  }
  &__button {
    background-color: $primary;
    color: $white;
    padding: 10px 25px;
    border: none;
    border-radius: 10px;
    text-align: center;
    font-size: 1.2rem;
    border: 1px solid $primary;
    cursor: pointer;
    transition: 300ms all ease;
    &:hover {
      background-color: $white;
      color: $primary;
    }
  }
}
.clients {
  &__mainTitle {
    color: $primary;
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 60px;
  }
}
// VIDEO
.backgroundVideo {
  width: 100%;
  height: 500px;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  background-color: white;
}

.homeContact {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  margin: 0;
  cursor: pointer;
  background: none;
  border-radius: 5px;
  font-size: 1.2rem;
  background-size: 200% 100%;
  transition: all 500ms ease;
  &-wpp {
    background-image: linear-gradient(to right, $white 50%, $wpp 50%);
    color: $wpp;
    border: 1px solid $wpp;
  }
  &-phone {
    background-image: linear-gradient(to right, $white 50%, $primary 50%);
    color: $primary;
    border: 1px solid $primary;
  }
  &:hover {
    background-position: -100% 0;
    color: $white;
  }
}

.ingresoAlSistema {
  border-radius: 10px;
  background-color: $white;
  border: 2px solid $primary;
  color: $primary;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    background-color: $primary;
    color: $white;
  }
}
.wppButton {
  width: 70px;
  height: 70px;
  background-color: $wpp;
  position: fixed;
  z-index: 900000;
  bottom: 50px;
  right: 50px;
  border-radius: 50%;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    animation: bellshake 1s infinite;
  }
}
.wppButtonContact {
  background: none;
  border: 1px solid $wpp;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $wpp;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    color: $white;
    background-color: $wpp;
  }
}

.alternateBackgroundSection {
  background: rgba(0, 51, 153, 0.15);
  padding: 30px 0;
}

.homeFooter {
  background-color: $primary;
  text-align: center;
  color: $white;
  padding: 30px;
  font-size: 1rem;
  position: relative;
  &__flex {
    display: flex;
    justify-content: space-around;
    text-align: left;
  }
  &__text {
    font-size: 1.2rem;
    margin-bottom: 20px;
    &__icon {
      margin-bottom: -5px;
      margin-right: 5px;
    }
  }
  &__icons {
    font-size: 2rem;
    // &__container {
    //   position: absolute;
    //   top: calc(50% - 1.5rem);
    //   right: 150px;
    // }
    &__button {
      color: $white;
      margin: 0 10px;
      &:hover {
        color: $white;
      }
    }
  }
}

.nav {
  &__filter {
    height: calc(100vh - 80px);
    width: 100vw;
    background-color: rgba($color: #000, $alpha: 0.6);
    position: absolute;
    top: 80px;
    left: 0;
    z-index: -10;
    transform: translateX(-100%);
  }
  &__list {
    &__item {
      padding: 0 10px;
      display: inline-block;
      position: relative;
      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        background: $primary;
        transition: width 0.3s ease 0s, left 0.3s ease 0s, color 0.3s ease;
        width: 0;
      }
      &:hover:after {
        width: 100%;
        left: 0;
      }
      &:hover {
        color: $primary;
      }
    }
    &__container {
      list-style: none;
      display: flex;
      margin: 0;
      font-size: 1.1rem;
      font-weight: 400;
    }
  }
  &__menu {
    &__mobile {
      display: none;
    }
  }
}

.video {
  &-title {
    color: $white;
    font-weight: 700;
    font-size: 3.5rem;
    margin: 0;
  }
  &-text {
    color: $white;
    font-size: 1.6rem;
  }
}

@media (max-width: 1700px) {
  .beneficios {
    &__image {
      height: 400px;
    }
  }
}
@media (max-width: 1464px) {
  .beneficios {
    &__image {
      height: 350px;
    }
  }
}
@media (max-width: 1280px) {
  .publicContainer {
    max-width: 1024px;
  }
}
@media (max-width: 1199px) {
  .beneficios {
    &__text {
      &__container {
        margin: 15px;
      }
    }
    &__image {
      margin: auto;
      &__container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media (max-width: 1024px) {
  .publicContainer {
    max-width: 768px;
  }
  .hero {
    background-position-x: left;
    padding-bottom: 140px;
  }
  .homeFooter {
    &__flex {
      flex-direction: column;
      text-align: center;
    }
  }

  .nav {
    &__list {
      &__item {
        margin: 10px 0;
        color: $white;
        &:after {
          background: $white;
        }
        &:hover:after {
          width: 100%;
          left: 0;
        }
        &:hover {
          color: $white;
        }
      }
      &__container {
        position: absolute;
        flex-direction: column;
        height: 100vh;
        width: 200px;
        background-color: $primary;
        top: 80px;
        right: -100%;
        justify-content: center;
        align-items: center;
        margin: 0 !important;
        padding: 0;
        transition: 300ms all ease;
      }
    }
    &__menu {
      &__mobile {
        display: block;
      }
    }
  }

  .active {
    right: 0;
  }
  .homeFooter {
    &__icons {
      &__container {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .videoMobile {
    display: none;
  }
  .publicContainer {
    max-width: 640px;
  }

  .hero {
    height: auto;
    padding: 50px 0px 140px;
    &__text {
      font-size: 2.5rem;
      padding: 0 20px;
      text-align: center;
    }
    &__button {
      margin: 5px 0 !important;
      width: 50%;
      &__container {
        flex-direction: column;
        align-items: center;
        padding: 0 30px;
      }
      &:hover {
        background-color: $primary;
        color: $white;
        border: 1px solid $white;
      }
    }
  }
  .beneficios {
    &__grid {
      padding: 50px 10px 0px;
    }
  }

  .price {
    &__container {
      padding: 80px 0 10px;
    }
    &__card {
      width: 250px;
    }
    &__title {
      padding-top: 120px;
      font-size: 1.8rem;
    }
    &__text {
      font-size: 1rem;
    }
    &__circle {
      width: 150px;
      height: 150px;
      position: absolute;
      border-radius: 50%;
      transform: skew(15deg) rotatez(10deg);
      top: -50px;
      left: 50px;
      z-index: 2;

      &__text {
        color: $white;
        text-align: center;
        position: relative;
        font-weight: 700;
        &__sol {
          position: absolute;
          font-size: 1.2rem;
          top: 15px;
          left: -20px;
        }
        &__precio {
          font-size: 4rem;
          font-family: sans-serif;
        }
        &__mes {
          position: absolute;
          font-size: 1.2rem;
          bottom: -5px;
          right: -20px;
        }
      }
    }
    &__bigCircle {
      width: 200px;
      height: 200px;
      background: $primary;
      position: absolute;
      border-radius: 50%;
      transform: skew(15deg) rotatez(10deg);
      top: -75px;
      left: 25px;
      z-index: 1;
    }
    &__button {
      position: absolute;
      padding: 5px 40px;
    }
  }
  .wppButton {
    display: none;
  }
  .video {
    &-title {
      font-size: 2rem;
    }
    &-text {
      font-size: 1.3rem;
    }
  }
  .celular {
    margin-top: -100px;
    padding: 50px 0px 0px;
    border-bottom: 1px solid $primary;
    &__image {
      display: none;
    }
    &__title {
      font-size: 2rem;
      text-align: center;
    }
    &__text {
      font-size: 1.2rem;
      padding: 0 30px;
    }
  }
}
@media (max-width: 500px) {
  .hero {
    &__button {
      width: 100%;
    }
  }
}
// .celular {
//   padding: 0px;
//
// }
@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}
